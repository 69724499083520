import React, { Component } from 'react';
import uniquid from "uniqid";
import Resizer from 'react-image-file-resizer';

import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import LableComponent from '../../reusables/info/LableComponent';
import SmallComponent from '../../reusables/info/SmallComponent';
import { Uploading, UploadingImage } from '../../config/UploadConstants';
import { CheckingInputs, DoNotRefresh, PleaseWait, ProcessFailed, Success, UnexpectedError } from '../../config/CommonConstants';
import { getUTCDate } from '../../utils/DateUtilities';
const ClientConfig = require('../../config/ClientConfig')[process.env.REACT_APP_ENV || "production"];

export default class UploadComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            memeTemplate: {
                fileName: null,
                name: null,
                imageKey: null,
                imageHash: null,
                tags: [],
                imageUploaded: false,
            },
            tagsAsText: "",
            memeTemplateUrl: undefined,
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            loginFormOpacity: 1,
            showLoadingSpinner: false,
            extraInfo: "",
            opacity: 1,
            reload: false,
        }
    }

    unsetBusyState = async () => {
        if (this.state.reload)
            return window.location.reload();
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };

    processTags = (e) => {
        let value = e.target.value || "";
        let processedTags = [];

        let tags = value.split(',');
        if (tags.length) {
            tags.forEach((tag) => {
                if (tag.trim().length)
                    processedTags.push(tag.trim());
            });
            return this.setState({ memeTemplate: { ...this.state.memeTemplate, tags: [...processedTags] } });
        }

    }

    setTagText = (e) => {
        let value = e.target.value;

        const expression = /[^A-Za-z.@0-9\s,'&/]/;
        if (!expression.exec(value))
            return this.setState({ tagsAsText: value });
    }

    resizeFile = (file, ext) => new Promise(resolve => {
        Resizer.imageFileResizer(file, 1280, 720, ext, 100, 0,
            uri => {
                resolve(uri);
            },
            'base64',
            500,
            500
        );
    });

    uploadImage = async (e) => {
        try {
            e.preventDefault();

            this.setState({ showCloseButton: false, showLoadingSpinner: true, showLoading: true, loadingTitle: Uploading, loadingDescription: UploadingImage, extraInfo: DoNotRefresh, opacity: 0.1 });

            let file = e.target.files[0];
            if (!file)
                throw new Error("We Thought You Will Select A File !");
            if (((file.size / 1024) / 1024) > 12) {
                throw new Error(`Your Selected File Is Of ${(Math.round((file.size / 1024) / 1024))} MB. Any File Must Not Be Greater Then 12 MB !`);
            }

            let allowdExtensions = ['jpeg', 'jpg', 'jpe', 'jif', 'jfif', 'jfi', 'jp2', 'j2k', 'jpf', 'jpx', 'jpm', 'mj2', 'png', 'gif'];
            let ext = file.name.split('.').pop().toLowerCase();
            if (!allowdExtensions.includes(ext))
                throw new Error(`Your Selected File Is A ${ext} File. Only ${allowdExtensions.join()} File(s) Can Uploaded !`);

            let image = { fileName: file.name, type: file.type, extension: ext };
            image.name = uniquid() + "_raw_image_" + uniquid() + "." + image.extension;
            image.content = (await this.resizeFile(file, ext)).split(',')[1];

            let date = new Date();
            let utcDate = getUTCDate();
            let request = { payload: { ...image, uploadDate: utcDate, uploadTime: date.toLocaleTimeString('en-IN') }, options: { uri: "/upload-image" } };
            let { result: { data: { code, tags, contentHash, name, _id } } } = await postRequest('/web/public/insert', request, uniquid());
            if (code === 200)
                throw new Error(`This image is already available in templates section under ${tags.join()} tags!`);

            let { memeTemplate } = this.state;
            memeTemplate.imageHash = contentHash;
            memeTemplate.imageKey = _id;
            memeTemplate.fileName = name;
            memeTemplate.name = uniquid() + "_meme_image_" + uniquid() + "." + image.extension;
            memeTemplate.imageUploaded = true;

            let selfImageUrl = ClientConfig.API_ENDPOINT + `/web/public/query/render-image?imageKey=` + _id;
            return this.setState({ showLoading: false, opacity: 1, memeTemplate: memeTemplate, memeTemplateUrl: selfImageUrl });
       
            // const fileReader = new FileReader();
            // fileReader.readAsDataURL(file);
            // fileReader.onload = async  ()=> {
            //     image.content = fileReader.result..split(',')[1];

            //     let date = new Date();
            //     let utcDate = getUTCDate();
            //     let request = { payload: { ...image, uploadDate: utcDate, uploadTime: date.toLocaleTimeString('en-IN') }, options: { uri: "/upload-image" } };
            //     let { result: { data: { code, tags, contentHash, name, _id } } } = await postRequest('/web/public/insert', request, uniquid());
            //     if (code === 200)
            //         throw new Error(`This image is already available in templates section under ${tags.join()} tags!`);

            //     let { memeTemplate } = this.state;
            //     memeTemplate.imageHash = contentHash;
            //     memeTemplate.imageKey = _id;
            //     memeTemplate.fileName = name;
            //     memeTemplate.name = uniquid() + "_meme_image_" + uniquid() + "." + image.extension;
            //     memeTemplate.imageUploaded = true;

            //     let selfImageUrl = ClientConfig.API_ENDPOINT + `/web/public/query/render-image?imageKey=` + _id;
            //     return this.setState({ showLoading: false, opacity: 1, memeTemplate: memeTemplate, memeTemplateUrl: selfImageUrl });
            // }
            // fileReader.error = function () {
            //     throw new Error(fileReader.error.message);
            // }
       
        } catch (error) {
            //reset file if error
            e.target.type = 'text';
            e.target.type = 'file';
            return this.setState({
                memeTemplate: {
                    fileName: null,
                    name: null,
                    imageKey: null,
                    imageHash: null,
                    imageUploaded: false,
                },
                showCloseButton: true, showLoadingSpinner: false, showLoading: true, loadingTitle: ProcessFailed, loadingDescription: error.message, extraInfo: UnexpectedError, opacity: 0.3
            });
        }
    }

    removeImage = () => {
        let { memeTemplate } = this.state;
        memeTemplate.imageKey = null;
        memeTemplate.imageHash = null;
        memeTemplate.imageUploaded = false;
        memeTemplate.fileName = null;
        memeTemplate.name = null;

        return this.setState({ memeTemplateUrl: undefined, memeTemplate: memeTemplate });
    }

    validateForm = () => {
        let { tags, imageUploaded, imageHash, imageKey } = this.state.memeTemplate;
        if (!tags || !tags.length || !imageHash || !imageUploaded || !imageKey)
            return true;
        return false;
    }

    saveMemeTemplate = async (e) => {
        try {
            e.preventDefault();

            if (this.validateForm())
                throw new Error("Fill Form Properly To Add Meme Templates, * Denotes Mandatory Fields !");

            this.setState({
                loadingTitle: PleaseWait,
                loadingDescription: CheckingInputs,
                showLoading: true,
                showLoadingSpinner: true,
                showCloseButton: false,
                extraInfo: DoNotRefresh,
                opacity: 0.3
            });

            let { memeTemplate } = this.state;
            let { result: { message } } = await postRequest("/web/public/insert", { payload: { ...memeTemplate }, options: { uri: "/upload-meme-template" } }, uniquid());
            return this.setState({
                memeTemplate: {
                    fileName: null,
                    name: null,
                    imageKey: null,
                    imageHash: null,
                    tags: [],
                    imageUploaded: false,
                },
                tagsAsText: "",
                loadingTitle: Success, loadingDescription: message, showCloseButton: true, reload: false, showLoadingSpinner: false
            });
        } catch (error) {
            return this.setState({
                showLoading: true, loadingTitle: ProcessFailed,
                loadingDescription: error.message,
                extraInfo: UnexpectedError,
                showLoadingSpinner: false,
                showCloseButton: true,
                opacity: 0.3
            });
        }
    }

    render() {
        return (
            <React.Fragment>
                <div style={{ opacity: this.state.opacity }}>
                    <div className="jumbotron jumbotron mt-0 mb-0 pb-0 pt-3 bg-white">
                        <div className="container-fluid bg-light p-3 pb-5">
                            <form onSubmit={this.saveMemeTemplate}>
                                <div className="form-row mt-4">
                                    <div className="form-group col-md-12">
                                        <LableComponent
                                            htmlFor="tags"
                                            classes="font-weight-bold"
                                            labelContent="Meme Template Tages *"
                                        />

                                        <input
                                            type="text"
                                            autoComplete="nope"
                                            value={this.state.tagsAsText}
                                            onBlur={this.processTags}
                                            onChange={this.setTagText}
                                            className="form-control"
                                            id="tags"
                                            placeholder="Comma(,) Seprated Tags For Meme Template"
                                            required={true}
                                            aria-describedby="memeTemplateTages"
                                            disabled={false}
                                        />

                                        <SmallComponent
                                            id="memeTemplateTages"
                                            classes="form-text font-weight-bold text-danger"
                                            content="Fill Tags For Meme Template (if multiple then seprate by Comma(,)) !"
                                        />

                                    </div>
                                    <div className="form-group col-md-12">
                                        <LableComponent
                                            htmlFor="memeTemplate"
                                            classes="font-weight-bold"
                                            labelContent="Meme Template Image *"
                                        />
                                        {!this.state.memeTemplate.imageUploaded ?
                                            <div className="custom-file">

                                                <input type="file"
                                                    className="custom-file form-control"
                                                    id="memeTemplate"
                                                    onChange={this.uploadImage}
                                                    accept="image/*"
                                                    required={true}
                                                    area-labelledby="memeTemplateHelp"
                                                />

                                                <LableComponent
                                                    htmlFor="memeTemplate"
                                                    classes="custom-file-label"
                                                    labelContent="Meme Template's Photo"
                                                />

                                                <SmallComponent
                                                    id="memeTemplateHelp"
                                                    classes="form-text text-danger font-weight-bold"
                                                    content="Select Or Capture Meme's Template"
                                                />

                                            </div> :
                                            <div className="card">
                                                <img loading="lazy"  src={this.state.memeTemplateUrl} className="card-img-top w-25 ml-auto mr-auto mt-3" alt="" />
                                                <div className="card-body text-center">
                                                    <button onClick={this.removeImage} className="btn btn-danger btn-sm w-25 ml-auto mr-auto" id="imageUrl"><i className="fas fa-trash"></i> Remove Uploaded Image</button>
                                                </div>
                                            </div>}
                                    </div>
                                </div>
                                <div className="py-4">
                                    <div className="col-12 text-center">
                                        <button type="submit" className="btn btn-primary font-weight-bold text-white w-50" >Save Meme Template</button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                {this.state.showLoading && (
                    <ApplicationBlocker
                        title={this.state.loadingTitle}
                        showCloseButton={this.state.showCloseButton}
                        unsetBusyState={this.unsetBusyState}
                        description={this.state.loadingDescription}
                        extraInfo={this.state.extraInfo}
                        showLoadingSpinner={this.state.showLoadingSpinner}
                    />
                )}
            </React.Fragment>
        )
    }

}
