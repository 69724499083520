import Axios from "axios";
import { encryptRequestPayload } from "../utils/Encryption";
var node_cryptojs = require("node-cryptojs-aes");
let CryptoJS = node_cryptojs.CryptoJS;
const Config = require("../config/ClientConfig")[process.env.REACT_APP_ENV || "production"];

// intercepting Http Failed requests
Axios.interceptors.response.use((response) => {
  return response;
}, function (error) {
  let { response: { data } } = error;
  return Promise.reject(data);
});


export const postRequest = async (baseUrl, requestPayload, aesKey, doNotEncrypt) => {
  try {
    if (!doNotEncrypt)
      requestPayload = await encryptRequestPayload(requestPayload, aesKey);
    let response = await Axios.post(
      Config.API_ENDPOINT + baseUrl,
      requestPayload
    );
    if (!doNotEncrypt)
      return JSON.parse(
        CryptoJS.enc.Utf8.stringify(
          await CryptoJS.AES.decrypt(response.data, aesKey)
        )
      );
    return response.data;
  } catch (error) {
    throw new Error(error.message);
  }
};
