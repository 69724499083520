export const ValidationError = "Validation Error !";
export const IncompleteData = "Incomplete Data !";
export const PleaseWait = "Please Wait !";
export const Downloading = "Downloading ...";
export const CheckingInputs = "Your Provided Inputs Are Being Checked, Please Wait It May Take A While !";
export const GettingMemeTemplates = "Getting Memes For You, Please Wait It May Take A While !";
export const DownloadingMemeTemplates = "Downloading Meme For You, Please Wait It May Take A While !";
export const DoNotRefresh = "Don't Refresh !";
export const ServerError = "Server Error !";
export const ProcessFailed = "Failed ...";
export const UnexpectedError = "Unexpected Error !";
export const Success = "Success ...";