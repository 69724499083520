import React, { Component } from "react";

class ApplicationBlocker extends Component {
  render() {
    return (
      <>
        <div
          className="modal fade show application-busy-wrapper"
          tabIndex="-1"
          role="dialog"
          data-backdrop="static"
          data-background="false"
        >
          <div
            className="modal-dialog application-busy-wrapper "
            role="document"
          >
            <div
              className="modal-content border application-busy-box-border-color application-busy-wrapper"
              style={{ marginTop: "50%" }}
            >
              <div className="modal-header">
                <h5 className="modal-title font-weight-bold text-info">{this.props.title}</h5>
                {this.props.showLoadingSpinner && (
                  <div className="text-center">
                    <div
                      className="spinner-border text-info text-right"
                      role="status"
                    >
                      <span className="sr-only">Loading...</span>
                    </div>
                  </div>
                )}
              </div>
              <div className="modal-body">
                <h6 className="lead">{this.props.description}</h6>
              </div>
              <div className="modal-footer">
                <p className="text-info text-left justify-content-start">
                  {this.props.extraInfo}
                </p>
                {this.props.showCloseButton ? (
                  <button
                    type="button"
                    className="btn btn-info"
                    onClick={this.props.unsetBusyState}
                  >
                    Close
                  </button>
                ) : (
                  false
                )}
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}
export default ApplicationBlocker;
