import React, { Component } from 'react'
import uniquid from "uniqid";


import LableComponent from '../../reusables/info/LableComponent';
import SmallComponent from '../../reusables/info/SmallComponent';
import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import { Uploading, UploadingImage, UploadingMeme } from '../../config/UploadConstants';
import { CheckingInputs, DoNotRefresh, PleaseWait, ProcessFailed, Success, UnexpectedError } from '../../config/CommonConstants';
import { getUTCDate } from '../../utils/DateUtilities';
const ClientConfig = require('../../config/ClientConfig')[process.env.REACT_APP_ENV || "production"];

export default class CreateMemeComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            image: null,
            canvasRef: React.createRef(),
            topText: '',
            bottomText: '',
            memeName: '',
            tags: [],
            tagsAsText: "",
            showLoading: false,
            loadingDescription: "",
            loadingTitle: "",
            showCloseButton: false,
            loginFormOpacity: 1,
            showLoadingSpinner: false,
            extraInfo: "",
            opacity: 1,
            reload: false,
            record: null
        };
    }

    unsetBusyState = async () => {
        if (this.state.reload)
            return window.location.reload();
        return this.setState({ showLoading: false, opacity: 1, redirect: "" });
    };


    setTopText = (e) => {
        return this.setState({ topText: e.target.value })
    }

    setMemeName = (e) => {
        return this.setState({ memeName: e.target.value })
    }

    setbottomText = (e) => {
        return this.setState({ bottomText: e.target.value })
    }


    processTags = (e) => {
        let value = e.target.value || "";
        let processedTags = [];

        let tags = value.split(',');
        if (tags.length) {
            tags.forEach((tag) => {
                if (tag.trim().length)
                    processedTags.push(tag.trim());
            });
            return this.setState({ tags: [...processedTags] });
        }

    }

    setTagText = (e) => {
        let value = e.target.value;

        const expression = /[^A-Za-z.@0-9\s,'&/]/;
        if (!expression.exec(value))
            return this.setState({ tagsAsText: value });
    }

    proceed = () => {
        if (this.state.image && this.state.canvasRef) {
            const canvas = this.state.canvasRef;
            const image = this.state.image;
            const ctx = canvas.current.getContext("2d")
            ctx.fillStyle = "black"
            ctx.fillRect(0, 0, 400, 400)
            ctx.drawImage(image, 0, 30, 400, 340)

            ctx.font = "20px Comic Sans MS"
            ctx.fillStyle = "white"
            ctx.textAlign = "center"

            ctx.fillText(this.state.topText, 200, 20, 399)
            ctx.fillText(this.state.bottomText, 200, 390, 399)
        }
    }

    saveMeme = async (e) => {
        try {
            e.preventDefault();

            this.validateForm();

            const imgData = this.state.canvasRef.current.toDataURL();


            this.setState({ showCloseButton: false, showLoadingSpinner: true, showLoading: true, loadingTitle: Uploading, loadingDescription: UploadingMeme, extraInfo: DoNotRefresh, opacity: 0.1 });

            let image = { fileName: this.state.record.name, type: this.state.record.imageKey.type, extension: this.state.record.imageKey.extension, templateKey: this.state.record._id, tags: [...this.state.tags, ...(this.state.record.tags || [])] };
            image.name = uniquid() + "_raw_image_" + uniquid() + "." + image.extension;
            image.content = imgData.split(',')[1];

            let date = new Date();
            let utcDate = getUTCDate();
            let request = { payload: { ...image, label: this.state.memeName, uploadDate: utcDate, uploadTime: date.toLocaleTimeString('en-IN') }, options: { uri: "/upload-meme" } };
            let { result: { data: { code, tags, contentHash, name, _id }, message } } = await postRequest('/web/public/insert', request, uniquid());
            if (code === 200)
                throw new Error(`This meme with same text is already available in memes tab under ${tags.join()} tags, hence can not saved again !`);

            return this.setState({
                loadingTitle: Success, loadingDescription: message, showCloseButton: true, reload: false, showLoadingSpinner: false
            });
        } catch (error) {
            return this.setState({
                showCloseButton: true, reload: false, showLoadingSpinner: false, showLoading: true, loadingTitle: ProcessFailed, loadingDescription: error.message, extraInfo: UnexpectedError, opacity: 0.3
            });
        }
    }


    validateForm = () => {
        let { tags, bottomText, topText, memeName } = this.state;
        if (!memeName || !memeName.trim().length)
            throw new Error("Name Of Meme Is Required !");
        if (!tags.length)
            throw new Error("At least one tag Is Required !");
        if ((!bottomText || !bottomText.trim().length) && (!topText || !topText.trim().length))
            throw new Error("Top or Bottom Text Is Required !");

        return false;
    }

    componentDidMount() {
        if (!this.props.location || !this.props.location.record) {
            return this.props.history.replace('/home/templates');
        }

        const { record } = this.props.location;
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.src = record.url;
        img.alt = "";
        img.onload = () => {
            this.setState({ image: img, record: record });
        }
    }

    render() {
        const { record } = this.props.location;
        this.proceed()
        return (
            <React.Fragment>
                {record ?
                    (
                        <div style={{ opacity: this.state.opacity }}>
                            <div className="jumbotron jumbotron mt-0 mb-0 pb-5 pt-0 bg-white">
                                <div className="container-fluid bg-light p-3 pb-5">
                                    <div className='row'>
                                        <div className='col-sm-4 text-center'>
                                            <div>
                                                <canvas
                                                    ref={this.state.canvasRef}
                                                    width={400}
                                                    height={400}
                                                />
                                            </div>
                                        </div>
                                        <div className='col-sm-8'>
                                            <form onSubmit={this.saveMemeTemplate}>
                                                <div className="form-row mt-4">
                                                    <div className="form-group col-sm-6">
                                                        <LableComponent
                                                            htmlFor="tags"
                                                            classes="font-weight-bold"
                                                            labelContent="Top Text"
                                                        />

                                                        <input
                                                            type="text"
                                                            autoComplete="nope"
                                                            value={this.state.topText}
                                                            onChange={this.setTopText}
                                                            className="form-control"
                                                            id="tags"
                                                            placeholder="Text to be added at the top of the image !"
                                                            aria-describedby="memeTemplateTages"
                                                            disabled={false}
                                                        />

                                                        <SmallComponent
                                                            id="memeTemplateTages"
                                                            classes="form-text font-weight-bold text-danger"
                                                            content="Enter text to Add at the Top !"
                                                        />

                                                    </div>
                                                    <div className="form-group col-sm-6">
                                                        <LableComponent
                                                            htmlFor="tags"
                                                            classes="font-weight-bold"
                                                            labelContent="Bottom Text "
                                                        />

                                                        <input
                                                            type="text"
                                                            autoComplete="nope"
                                                            value={this.state.bottomText}
                                                            onChange={this.setbottomText}
                                                            className="form-control"
                                                            id="tags"
                                                            placeholder="Text to be added at bottom of the image !"
                                                            aria-describedby="memeTemplateTages"
                                                            disabled={false}
                                                        />

                                                        <SmallComponent
                                                            id="memeTemplateTages"
                                                            classes="form-text font-weight-bold text-danger"
                                                            content="Enter text to Add at the Bottom !"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="form-row mt-4">
                                                    <div className="form-group col-sm-6">
                                                        <LableComponent
                                                            htmlFor="tags"
                                                            classes="font-weight-bold"
                                                            labelContent="Name Of Meme *"
                                                        />

                                                        <input
                                                            type="text"
                                                            autoComplete="nope"
                                                            value={this.state.memeName}
                                                            onChange={this.setMemeName}
                                                            className="form-control"
                                                            id="tags"
                                                            placeholder="Text to be added at the top of the image !"
                                                            aria-describedby="memeTemplateTages"
                                                            disabled={false}
                                                        />

                                                        <SmallComponent
                                                            id="memeTemplateTages"
                                                            classes="form-text font-weight-bold text-danger"
                                                            content="Enter text to Add at the Top !"
                                                        />

                                                    </div>

                                                    <div className="form-group col-md-6">
                                                        <LableComponent
                                                            htmlFor="tags"
                                                            classes="font-weight-bold"
                                                            labelContent="Meme Template Tages *"
                                                        />

                                                        <input
                                                            type="text"
                                                            autoComplete="nope"
                                                            value={this.state.tagsAsText}
                                                            onBlur={this.processTags}
                                                            onChange={this.setTagText}
                                                            className="form-control"
                                                            id="tags"
                                                            placeholder="Comma(,) Seprated Tags For Meme Template"
                                                            required={true}
                                                            aria-describedby="memeTemplateTages"
                                                            disabled={false}
                                                        />

                                                        <SmallComponent
                                                            id="memeTemplateTages"
                                                            classes="form-text font-weight-bold text-danger"
                                                            content="Fill Tags For Meme Template (if multiple then seprate by Comma(,)) !"
                                                        />

                                                    </div>
                                                </div>
                                                <div className="py-4">
                                                    <div className="col-12 text-center">
                                                        <button onClick={this.saveMeme} className="btn btn-primary font-weight-bold text-white w-50" >Save Meme</button>
                                                    </div>
                                                </div>
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>)
                    : ""}
                {this.state.showLoading && (
                    <ApplicationBlocker
                        title={this.state.loadingTitle}
                        showCloseButton={this.state.showCloseButton}
                        unsetBusyState={this.unsetBusyState}
                        description={this.state.loadingDescription}
                        extraInfo={this.state.extraInfo}
                        showLoadingSpinner={this.state.showLoadingSpinner}
                    />
                )}
            </React.Fragment>
        )
    }
}
