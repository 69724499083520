import React, { Component } from 'react'

export default class FooterComponent extends Component {
    render() {
        return (
            <div className="fixed-bottom pt-1 bg-light">
                <p className="text-muted text-center pb-0">&copy; 2022-2023 </p>
            </div>
        )
    }
}
