import React, { Component } from 'react'

export default class LableComponent extends Component {
    render() {
        return (
            <label
                htmlFor={this.props.htmlFor}
                className={this.props.classes}
                style={this.props.style}
            >
                {this.props.labelContent}
            </label>
        )
    }
}
