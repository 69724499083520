import React, { Component } from 'react'

export default class SmallComponent extends Component {
    render() {
        return (
            <small
                id={this.props.id}
                className={this.props.classes}
                style={this.props.style}
            >
                {this.props.content}
            </small>
        )
    }
}
