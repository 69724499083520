export const todayDateString = () => {
  return new Date(getUTCDate()).toDateString();
};

export const yeasterdayDateString = () => {
  let date = getUTCDate();
  date.setUTCDate(date.getUTCDate() - 1);
  return date.toDateString();
};

export const yeasterdayDate = () => {
    let date = getUTCDate();
    date.setUTCDate(date.getUTCDate() - 1);
    return date;
  };
  

export const getUTCDate = () => {
  let date = new Date();
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
};

export const resetUTCDate = (date) => {
  date.setUTCHours(0);
  date.setUTCMinutes(0);
  date.setUTCSeconds(0);
  date.setUTCMilliseconds(0);
  return date;
};
