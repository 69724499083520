import React, { Component } from 'react'
import uniquid from "uniqid";

import ApplicationBlocker from "../../reusables/loaders/ApplicationBlocker";
import { postRequest } from "../../services/HttpService";
import { DoNotRefresh, GettingMemeTemplates, PleaseWait, ProcessFailed, UnexpectedError } from '../../config/CommonConstants';
const ClientConfig = require('../../config/ClientConfig')[process.env.REACT_APP_ENV || "production"];


export default class TemplatesComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templates: [],
      totalTemplates: null,
      fetched: 0,
      showLoading: false,
      loadingDescription: "",
      loadingTitle: "",
      showCloseButton: false,
      loginFormOpacity: 1,
      showLoadingSpinner: false,
      extraInfo: "",
      opacity: 1,
      reload: false,
    }
  }


  fetchTemplates = async () => {
    try {
      this.setState({
        loadingTitle: PleaseWait,
        loadingDescription: GettingMemeTemplates,
        showLoading: true,
        showLoadingSpinner: true,
        showCloseButton: false,
        extraInfo: DoNotRefresh,
        opacity: 0.3
      });

      let { result: { memeTemplates: templates = [], count } } = await postRequest("/web/public/query", { payload: { filter: {}, nested: [{ path: 'imageKey', select: "_id extension type" }], fields: { tags: 1, name: 1, imageHash: 1 }, skip: this.state.fetched, limit: 12 }, options: { uri: "/fetch-meme-templates" } }, uniquid());
      return this.setState({
        showLoading: false,
        templates: [...this.state.templates, ...templates],
        fetched: this.state.fetched + templates.length,
        totalTemplates: count,
        opacity: 1
      });
    } catch (error) {
      return this.setState({
        showLoading: true, loadingTitle: ProcessFailed,
        loadingDescription: error.message,
        extraInfo: UnexpectedError,
        showLoadingSpinner: false,
        showCloseButton: true,
        opacity: 0.3
      });
    }
  }

  componentDidMount() {
    this.fetchTemplates();
  }


  render() {
    return (
      <React.Fragment>
        <div style={{ opacity: this.state.opacity }}>
          <div className="jumbotron jumbotron mt-0 mb-0 pb-5 pt-0 bg-white">
            <div className="container-fluid bg-light p-3">
              <div className='row p-1 w-100'>
                {this.state.templates.map((template) => {
                  return (<div className='col-md-4' key={template._id}>
                    <div className="card w-100 m-3" >
                      <div className='w-100'>
                        <img loading="lazy"  className="card-img-top m-2" src={`${ClientConfig.API_ENDPOINT}/web/public/query/render-image?imageKey=${template.imageKey._id}`} alt="Meme Template" style={{ width: '50%' }} />
                      </div>
                      <div className="card-body">
                        <h5 className="card-title">{template.tags[0]}</h5>
                        <p className="card-text">Tags :- {template.tags.join()}.</p>

                        <button onClick={() => {
                          this.props.history.push({
                            pathname: "/home/templates/edit",
                            record: { ...template, url: `${ClientConfig.API_ENDPOINT}/web/public/query/render-image?imageKey=${template.imageKey._id}` }
                          })
                        }} className="btn btn-primary font-weight-bold text-white w-100" >Use Template</button>

                        <a rel="noreferrer" target="_blank" href={`${ClientConfig.API_ENDPOINT}/web/public/query/download-template?imageKey=${template.imageKey._id}`}><button className=' btn btn-primary font-weight-bold text-white w-100 mt-2'>Download Template</button></a>

                      </div>
                    </div></div>)
                })}
              </div>
              {this.state.fetched !== this.state.totalTemplates ?
                (<div className="py-4">
                  <div className="col-12 text-center">
                    <button onClick={this.fetchTemplates} className="btn btn-primary font-weight-bold text-white w-50" >View More Template</button>
                  </div>
                </div>)
                : ""
              }
            </div>
          </div>
        </div>
        {this.state.showLoading && (
          <ApplicationBlocker
            title={this.state.loadingTitle}
            showCloseButton={this.state.showCloseButton}
            unsetBusyState={this.unsetBusyState}
            description={this.state.loadingDescription}
            extraInfo={this.state.extraInfo}
            showLoadingSpinner={this.state.showLoadingSpinner}
          />
        )}
      </React.Fragment>
    )
  }
}
