import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import { Switch, Route } from "react-router-dom";

import FooterComponent from "./FooterComponent";
import CreateMemeComponent from "./memes/CreateMemeComponent";
import MemesComponent from "./memes/MemesComponent";
import NavbarComponent from "./NavbarComponent";
import TemplatesComponent from "./templates/TemplatesComponent";
import UploadComponent from "./uploads/UploadComponent";


class HomeComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    return (
      <React.Fragment>
        <div style={{ opacity: this.state.opacity }}>
          <NavbarComponent user={this.state.user} />
          <div style={{ position: "relative", top: "4rem" }}>
            <Switch>

              <Route
                exact
                path="/home/templates"
                component={TemplatesComponent}
              />

              <Route
                exact
                path="/home/memes"
                component={MemesComponent}
              />

              <Route
                exact
                path="/home/upload"
                component={UploadComponent}
              />

              <Route
                exact
                path="/home/templates/edit"
                component={CreateMemeComponent}
              />

              <Route path="/*" component={() => <Redirect to="/home/templates" />} />

            </Switch>

          </div>
        </div>
        <FooterComponent />

      </React.Fragment>
    );
  }
}

export default HomeComponent;
