
import { CryptoJS } from "node-cryptojs-aes";
const encryptAESKeyWithRSA = require("./EncryptionHelper.min.js");
export const encryptRequestPayload = async (requestPayload,aesKey) => {
  let rqek = await encryptAESKeyWithRSA(aesKey);
  let rqp = CryptoJS.AES.encrypt(
    JSON.stringify(requestPayload),
    aesKey
  ).toString();
  return { rqp, rqek };
};
