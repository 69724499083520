module.exports = {
  development: {
    API_ENDPOINT: "http://localhost:9090",
    ENV: "development",
  },
  production: {
    API_ENDPOINT: "https://api-dot-memehub.pawanbishnoi.dev",
    ENV: "production",
  },
};
